<template>
    <div class="information per-main">
        <a-form-model :model="form" ref="ruleForm" :rules="rules">
            <a-form-model-item prop="oldPassword">
                <div class="form-item">
                    <div class="label">旧密码</div>
                    <div class="input">
                        <a-input-password v-model="form.oldPassword"/>
                    </div>
                    
                </div>
            </a-form-model-item>
            <a-form-model-item prop="newPassword"> 
                <div class="form-item" >
                    <div class="label">新密码</div>
                    <div class="input">
                        <a-input-password v-model="form.newPassword" />
                    </div>
                </div>
            </a-form-model-item>
            <a-form-model-item prop="confirm">
                <div class="form-item" >
                    <div class="label">确认密码</div>
                    <div class="input">
                        <a-input-password v-model="form.confirm" />
                    </div>
                </div>
            </a-form-model-item>
            <a-form-model-item >
                <div class="form-item">
                    <div class="label">&nbsp;</div>
                    <div class="input btn" >
                        <a-button :loading="loading" @click="save">保存</a-button>
                    </div>
                </div>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { Base64 } from 'js-base64'
import { UpdateMemberPassword } from '@/api/user'
export default {
    metaInfo() {
		return {
			title: '修改密码-个人中心-文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: '修改密码-个人中心-文物出版社'
                },
                {
                    name: 'description',
					content: '修改密码-个人中心-文物出版社'
                }
            ]
		}
	},
    data(){
        let validateNewPw = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入新密码！'))
            } else if (value == this.form.oldPassword) {
                callback(new Error('新密码不能与旧密码一致！'))
            } else {
                callback()
            }
        }
        let validateConfirm = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码！'))
            } else if (value !== this.form.newPassword) {
                callback(new Error('两次输入密码不一致！'))
            } else {
                callback()
            }
        }

        return{
            form:{
                newPassword:'',
                oldPassword:'',
                confirm:''
            },
            rules:{
                oldPassword: [
                    { required: true, message: '请输入旧密码', trigger: 'blur' },
                ],
                newPassword: [{ validator: validateNewPw, trigger: 'blur' }],
                confirm: [{ validator: validateConfirm, trigger: 'blur' }],
            },
            loading:false,
        }
    },
    methods:{

        // 保存
        save(){
            this.$refs.ruleForm.validate(valid => {
                console.log(valid);
                if (valid) {
                    this.loading = true
                    const  { newPassword , oldPassword } = this.form
                    UpdateMemberPassword(Base64.encode(JSON.stringify({ newPassword , oldPassword }))).then(res => {
                        this.$refs.ruleForm.resetFields()
                        this.$message.success(res.msg)
                        this.loading = false
                    }).catch(err => {
                        this.loading = false
                    })
                } else {
                    return false;
                }
            });
        },
    },
}
</script>
<style lang="less" scoped>
.information{
    display: flex;
    justify-content: center;
    padding: 30px 140px 0 0 ;
    :deep(.ant-input){
        width: 500px;
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        font-size: 16px;
        color: rgb(85, 85, 85);
    }
    :deep(.ant-input-disabled){
        background-color: #eeeeee;
        border-color: #eeeeee !important;
    }
}
.form-item{
    display: flex;
    align-items: center;
    position: relative;
    .label{
        width: 65PX;
        text-align: justify;
        text-align-last: justify;
        margin-right: 45px;
        font-size: 16px;
        color: rgb(51, 51, 51);
        font-weight: bold;
    }
    .input{
        position: relative;
    }
}
.icon_user{
    font-size: 100px !important;
}
:deep(.ant-avatar){
    background-color: #fff;
    position: relative;
    .ant-avatar-string{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0 ;
        top: 0;
        transform: scale(1) translateX(0) !important;
    }

}
.loading{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0 ;
    top: 0;
    z-index: 3;
    background-color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    color: #fff;
}
.anticon-loading{
    line-height: 1;
}
.ant-upload-text{
    line-height: 1;
    font-size: 14px;
    margin-top: 5px;
}
.handle{
    position: absolute;
    left: calc(100% + 30px);
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    font-size: 16px;
    color: rgb(136, 136, 136);
    &.edit{
        text-decoration: underline;
        cursor: pointer;
    }
}

:deep(.avatar-uploader){
    .ant-upload{
        width: auto;
        height: auto;
        white-space: nowrap;
        margin: 0 !important;
        line-height: 1;
        border: none;
        background-color: transparent;
        font-size: 16px;
        padding: 0 !important;
        color: rgb(136, 136, 136);
        text-decoration: underline;
    }
}
.btn{
    flex: 1;
    text-align: center;
    margin-top: 50px;
    .ant-btn{
        min-width: 200px;
        padding: 0 20px;
        height: 50px;
        line-height: 50px;
        border-radius: 25px;
        background-color: #00ffc0;
        border-color: #00ffc0;
        font-size: 0.18rem;
        color: rgb(0, 0, 0);
        &:focus,
        &:hover {
            background-color: #79f9d9;
            border-color: #79f9d9;
            color: #000;
        }
        &:active {
            background-color: #00ff7e;
            border-color: #00ff7e;
            color: #000;
        }
    }
}
:deep(.ant-form-explain){
    padding-left: calc( 65Px + 45px);
}
</style>